body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

footera {
  position: fixed;
  height: 100px;
  bottom: 0;
  width: 100%;
}

.test {
  border: 1px red;
}
.month-picker {
  position: relative; }
  .month-picker > .rmp-container {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 100;
    top: 1px;
    left: -10000px;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; }
    @media screen and (max-width: 767px) {
      .month-picker > .rmp-container {
        position: fixed;
        top: 0;
        left: -10000px;
        width: 100%;
        height: 100%;
        transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; } }
    .month-picker > .rmp-container.rmp-table {
      display: table; }
    .month-picker > .rmp-container.show {
      left: 0;
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
      .month-picker > .rmp-container.show .rmp-overlay {
        left: 0; }
    .month-picker > .rmp-container .rmp-overlay {
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: 9;
      top: 0;
      left: -10000px;
      opacity: 1;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      will-change: opacity;
      transform: translateZ(0);
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
      @media screen and (max-width: 767px) {
        .month-picker > .rmp-container .rmp-overlay {
          background-color: rgba(0, 0, 0, 0.25); } }
    .month-picker > .rmp-container .rmp-cell {
      display: table-cell;
      vertical-align: middle;
      box-sizing: border-box;
      width: 100%;
      height: 100%; }
      @media screen and (max-width: 767px) {
        .month-picker > .rmp-container .rmp-cell {
          vertical-align: bottom; } }
    .month-picker > .rmp-container .rmp-popup {
      position: absolute;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
      margin: 0 auto;
      z-index: 10;
      font-size: 1.2rem;
      opacity: 0;
      border-radius: 3px;
      padding: 0.4rem;
      box-sizing: content-box; }
      @media screen and (max-width: 767px) {
        .month-picker > .rmp-container .rmp-popup {
          box-sizing: border-box;
          bottom: 0;
          width: 100%;
          max-width: 100%;
          height: 14.4rem;
          transform: translate3d(0, 14.4rem, 0); }
          .month-picker > .rmp-container .rmp-popup.range {
            height: 28rem;
            transform: translate3d(0, 28rem, 0); }
            .month-picker > .rmp-container .rmp-popup.range .rmp-pad {
              margin-top: 0.4rem; }
              .month-picker > .rmp-container .rmp-popup.range .rmp-pad:first-of-type {
                margin-top: 0; } }
      @media screen and (min-width: 768px) {
        .month-picker > .rmp-container .rmp-popup {
          transform: translate3d(0, -64px, 0);
          top: 0;
          width: 20rem; }
          .month-picker > .rmp-container .rmp-popup.range {
            width: 40.6rem;
            padding: 0.6rem; } }
      .month-picker > .rmp-container .rmp-popup.show {
        opacity: 1;
        transform: translate3d(0, 0, 0); }
      .month-picker > .rmp-container .rmp-popup:after {
        content: ' ';
        clear: both;
        display: table; }
      .month-picker > .rmp-container .rmp-popup .rmp-pad {
        position: relative; }
        @media screen and (min-width: 768px) {
          .month-picker > .rmp-container .rmp-popup .rmp-pad {
            box-sizing: border-box;
            float: left;
            width: 20rem; }
            .month-picker > .rmp-container .rmp-popup .rmp-pad:nth-of-type(2) {
              float: right; } }
        .month-picker > .rmp-container .rmp-popup .rmp-pad > div label {
          display: block;
          font-size: 1.4rem;
          text-align: center;
          line-height: 3.4rem; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div label b {
            font-weight: normal;
            margin-right: 0.5em; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
          font-style: normal;
          text-align: center;
          width: 3.4rem;
          height: 3.4rem;
          line-height: 3.4rem;
          position: absolute;
          top: 0; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div i.prev {
            left: 0; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div i.next {
            right: 0; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad ul, .month-picker > .rmp-container .rmp-popup .rmp-pad li {
          list-style-type: none;
          margin: 0;
          padding: 0; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad ul {
          display: block;
          width: 100%; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad ul:after {
            content: ' ';
            clear: both;
            display: table; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad li {
          display: block;
          float: left;
          text-align: center;
          font-size: 1.15rem;
          border-radius: 3px;
          line-height: 3.3rem;
          box-sizing: border-box;
          padding: 0.05rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          *white-space: nowrap;
          overflow: hidden;
          -webkit-font-smoothing: antialiased;
          -webkit-text-stroke-width: 0.2px;
          -moz-osx-font-smoothing: grayscale;
          transition: background-color 200ms ease-in-out, color 200ms ease-in-out; }
          @media screen and (max-width: 767px) {
            .month-picker > .rmp-container .rmp-popup .rmp-pad li {
              width: 25%; } }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup .rmp-pad li {
              width: 33.3333333333%; } }
          .month-picker > .rmp-container .rmp-popup .rmp-pad li.multiple {
            background-clip: content-box; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad li.range {
            border-radius: 1px; }
      .month-picker > .rmp-container .rmp-popup.light {
        color: #666;
        background-color: rgba(255, 255, 255, 0.96); }
        @media screen and (max-width: 767px) {
          .month-picker > .rmp-container .rmp-popup.light {
            border-top: 1px solid #ccc;
            box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.08); }
            .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
              border-top: 1px solid rgba(204, 204, 204, 0.5); }
              .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad:first-of-type {
                border-top: 0; } }
        @media screen and (min-width: 768px) {
          .month-picker > .rmp-container .rmp-popup.light {
            border: 1px solid #ccc;
            box-shadow: 0 1px 5px #ddd; }
            .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
              background-color: rgba(238, 238, 238, 0.9); } }
        .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn {
          cursor: pointer;
          moz-user-select: -moz-none;
          -moz-user-select: none;
          -o-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none; }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
              background-color: rgba(255, 227, 160, 0.59); } }
          .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn.select {
            background-color: #d3d3d3; }
        .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active:hover {
          background-color: rgba(31, 42, 58, 0.73);
          color: white; }
        .month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable:hover, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable:hover {
          background-color: transparent;
          color: #bbb;
          cursor: default; }
      .month-picker > .rmp-container .rmp-popup.dark {
        color: #fff;
        background-color: rgba(50, 50, 50, 0.96); }
        @media screen and (max-width: 767px) {
          .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
            border-top: 1px solid rgba(113, 113, 113, 0.41); }
            .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad:first-of-type {
              border-top: 0; } }
        @media screen and (min-width: 768px) {
          .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
            background-color: rgba(70, 70, 70, 0.9); } }
        .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn {
          cursor: pointer;
          moz-user-select: -moz-none;
          -moz-user-select: none;
          -o-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none; }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn:hover {
              background-color: rgba(255, 210, 96, 0.33); } }
          .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn.select {
            background-color: #262828; }
        .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active:hover {
          background-color: rgba(189, 211, 242, 0.7);
          color: #303030; }
        .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable:hover, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.disable, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.disable:hover {
          background-color: transparent;
          color: #717171;
          cursor: default; }

.month-picker > .box {
  cursor: pointer; }
  @media screen and (max-width: 767px) {
    .month-picker > .box {
      box-sizing: border-box;
      line-height: 5rem;
      font-size: 1.32rem;
      color: #777777;
      text-align: right; } }
  @media screen and (min-width: 768px) {
    .month-picker > .box {
      border: 1px solid #e0e0e0;
      background-color: #f6f6f6;
      padding-left: 0.9rem;
      box-sizing: border-box;
      line-height: 3.2rem;
      font-size: 1.3rem;
      color: #525252; }
      .month-picker > .box > label {
        font-size: 1.3rem;
        color: #525252; } }

.month-picker .tab.btn {
  font-size: 0; }
  .month-picker .tab.btn:before {
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-family: "icomoon";
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    font-size: 1.8rem;
    line-height: 3.4rem;
    text-align: center;
    width: 3.4rem; }
  .month-picker .tab.btn.prev:before {
    content: ""; }
  .month-picker .tab.btn.next:before {
    content: ""; }

.years-panel {
  position: fixed;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: -10000px;
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; }
  .years-panel.table {
    display: table; }
  .years-panel.show {
    left: 0;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
  .years-panel.show > .overlay {
    left: 0;
    background-color: rgba(82, 82, 82, 0.49); }
  .years-panel > .overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
    top: 0;
    left: -10000px;
    opacity: 1;
    -webkit-tap-highlight-color: transparent;
    will-change: opacity;
    transform: translateZ(0);
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
  .years-panel .cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%; }
  .years-panel .popup {
    display: inline-block;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
    margin: 0 auto;
    z-index: 10;
    font-size: 1.2rem;
    opacity: 0;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.81);
    padding: 1.8rem; }
    .years-panel .popup textarea {
      outline: none;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 1.5rem;
      width: 20rem;
      line-height: 150%;
      padding: 1rem; }
    .years-panel .popup:after {
      content: ' ';
      clear: both;
      display: table; }
  .years-panel.show .popup {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

.list-area {
  margin-top: 5rem; }
  @media screen and (min-width: 768px) {
    .list-area {
      width: 500px;
      margin-left: auto;
      margin-right: auto; } }
  @media screen and (max-width: 767px) {
    .list-area > ul {
      border-bottom: 1px solid #e8e8e8; }
      .list-area > ul > li {
        position: relative;
        background-color: #fff;
        border-top: 1px solid #e8e8e8;
        padding: 0 1.6rem; }
        .list-area > ul > li:after {
          content: ' ';
          clear: both;
          display: table; }
        .list-area > ul > li > label {
          float: left;
          display: block;
          line-height: 5rem;
          color: #440f24;
          font-size: 1.12rem; }
          .list-area > ul > li > label b {
            font-size: 1.1em; }
          .list-area > ul > li > label span {
            position: absolute;
            display: block;
            font-size: 0.8rem;
            color: #bfbfbf;
            left: 1.6rem;
            top: 1.5em; }
            .list-area > ul > li > label span i {
              display: inline-block;
              font-style: normal; }
              .list-area > ul > li > label span i.sub-btn {
                display: none; }
        .list-area > ul > li .edit {
          margin-left: 9rem;
          line-height: 5rem; } }
  @media screen and (min-width: 768px) {
    .list-area > ul {
      padding: 1.8rem; }
      .list-area > ul > li {
        padding: 0.5rem 0 1.4rem;
        font-size: 1.3rem; }
        .list-area > ul > li:after {
          content: ' ';
          clear: both;
          display: table; }
        .list-area > ul > li > label {
          display: block;
          margin-right: 1rem;
          padding: 1px;
          color: #898989;
          font-size: 1.1rem;
          margin-bottom: 0.5em; }
          .list-area > ul > li > label b {
            display: block;
            font-size: 1.1em; }
          .list-area > ul > li > label span {
            display: block;
            font-size: 0.9em;
            color: #afafaf;
            margin-top: 0.4em; }
            .list-area > ul > li > label span i {
              display: inline-block;
              font-style: normal; }
              .list-area > ul > li > label span i.sub-btn {
                padding: 0.1rem 0.3rem;
                margin-left: 1.5rem;
                border-radius: 3px;
                background-color: #696969;
                color: white;
                font-size: 0.8em;
                cursor: pointer; }
        .list-area > ul > li .edit {
          padding: 1px;
          height: 3.3rem; } }

          ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0; }

  .MuiFormControl-root{
      width: 100%;
  }

  .customModalBody {
        padding: 0 !important;
    }
 .MuiTableCell-head{
     font-weight: bolder !important;
 }

 .modal{
     z-index: 9999;
 }

 .footer {
    height: 60px;
    z-index: 97;
    -webkit-box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.13);
    box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.13);
    bottom: 0;
    right: 0;
    width: 100%;
}

.react-confirm-alert-body {
    font-family: Arial, Helvetica, sans-serif;
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #cee6ff !important;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
    color: #666;
}

.swal-icon:first-child {
    margin-top: -7px;
    margin-bottom: -7px;
}
.swal-modal{
    width: 9%;
    z-index: 99999;
    background: transparent;
}

.pointer-none{
  cursor: not-allowed;
  pointer-events: none !important;
  background-color: #EEE;
}

#__react-alert__{
    z-index: 999999;
}