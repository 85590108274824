.generatedContent {
    padding-top: 1rem;
}

.valueText {
    font-weight: bold;
}

.tableWrapper {
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.button {
    color: white;
    background-color: #3f51b5;
    padding: 6px 15px;
    min-width: 140px;
    border-radius: 4px;
    border-style: none;
    text-transform: uppercase;
}

.downloadBtn {
    cursor: pointer;
    color: #1BC5BD;
}