.wrapper {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-gap: 2rem;
  margin-bottom: 2rem;
}

.cardView {
  border-radius: 1rem;
}

.totalOutletWrapper {
  grid-column: span 5;
  width: 99%;
}

.otherCardWrapper {
  grid-column: span 2;
  width: 23.5%;
  position: absolute;
  left: 46%;
}

.lastCardWrapper {
  grid-column: span 4;
  width: 28%;
  position: absolute;
  left: 72%;    
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 1rem;
  background: linear-gradient(to bottom, #059EDA, #3C62AE);
  color: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  font-weight: bold;
}

.cardContent {
  padding: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  background-color: white;
}

.progressContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.progressWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.progressWrapper svg {
  max-width: 76px;
}

.progressTitle {
  font-weight: bold;
}

.totalValue {
  color: #059EDA;
  font-size: rfs(6.2rem);
  font-weight: bold;
  line-height: 1.1;
}

@media (max-width: 576px) {
  .wrapper {
    grid-template-columns: 1fr;
    margin-bottom: 3rem;
  }
}
