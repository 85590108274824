.container {
  min-height: calc(100vh - 65px);
  color: #1E519F;
  background-color: #F5F7F9;
  text-transform: uppercase;
  font-size: 12px;
}

.topBar {
  width: calc(100% - 70px);
  position: fixed;
  top: 0;
  left: 70px;
  background-color: #F5F7F9;
  padding: 0.5rem 1rem 1rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.filterSectionMobile {
  display: none;
}

.logoMenuWrapper {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.mobileMenuIcon {
  display: none;
}

.logo {
  width: 150px;
  height: 75px;
}

.selectionWrapper {
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 1rem;
}

.submitButton {
  height: fit-content;
  border-style: none;
  padding: 8.5px 1rem;
  border-radius: 0.8rem;
  background: linear-gradient(to bottom, #059EDA, #3C62AE);
  color: white;
}

.contentWrapper {
  height: 100%;
  padding: 0 1rem;
  padding-top: 3.5rem;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 2rem;
}

.leftSection {
  grid-column: span 4;
}

.rightSection {
  grid-column: span 8;
}

@media (max-width: 1500px) {
  .container {
    padding-bottom: 1rem;
  }

  .contentWrapper {
    padding: 0 1rem;
    padding-top: 4.2rem;
    grid-gap: 1rem;
    grid-template-columns: 1fr 3fr;
  }

  .topBar {
    padding: 0.5rem 1rem 1rem 1rem;
  }

  .logo {
    width: 130px;
    margin-right: 0.5rem;
    height: 70px;
  }

  .selectionWrapper {
    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding-bottom: 1rem;
  }

  .submitButton {
    padding: 8.5px 1rem;
    margin-top: 7px;
    margin-right: 2rem;
  }

  .leftSection {
    grid-column: span 1;
  }
  
  .rightSection {
    grid-column: span 1;
  }
}

@media screen and (min-width:577px) and (max-width:1280px) and (orientation:landscape) {
  .container {
    font-size: 11px;
  }

  .topBar {
    width: 100%;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .mobileMenuIcon {
    display: block;
    margin-left: 1rem;
  }

  .logo {
    width: 100px;
    margin-right: 0;
    margin-left: 0;
    height: 54px;
  }

  .filterSectionDesktop {
    width: 100%;
  }

  .selectionWrapper {
    gap: 1rem;
    padding-bottom: 1rem;
  }

  .submitButton {
    margin-top: 0;
    margin-right: 0;
  }

  .contentWrapper {
    padding-top: 7.5rem;
  }
}


@media screen and (min-width:577px) and (max-width:850px) and (orientation:portrait) {
  .container {
    font-size: 11px;
  }
  
  .topBar {
    width: 100%;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .filterSectionDesktop {
    display: none;
  }

  .filterSectionMobile {
    display: block;
  }

  .mobileMenuIcon {
    display: block;
  }

  .logo {
    width: 100px;
    margin-right: 0;
    height: 54px;
  }

  .selectionWrapper {
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
    padding-bottom: 1rem;

  }

  .submitButton {
    margin-top: 0;
    margin-right: 0;
  }

  .contentWrapper {
    height: fit-content;
    padding-top: 40px;
    padding-bottom: 10rem;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }

  .leftSection {
    grid-column: span 1;
    order: 2;
  }
  
  .rightSection {
    grid-column: span 1;
  }
}

@media (max-width: 576px) {
  .topBar {
    width: 100%;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .filterSectionDesktop {
    display: none;
  }

  .filterSectionMobile {
    display: block;
  }

  .mobileMenuIcon {
    display: block;
  }

  .logo {
    width: 100px;
    margin-right: 0;
    height: 54px;
  }

  .selectionWrapper {
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
    padding-bottom: 1rem;

  }

  .submitButton {
    margin-top: 0;
    margin-right: 0;
  }

  .contentWrapper {
    height: fit-content;
    padding-top: 40px;
    padding-bottom: 10rem;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }

  .leftSection {
    grid-column: span 1;
  }
  
  .rightSection {
    grid-column: span 1;
  }
}
