.container {
    padding: 2rem;
    margin-top: -65px;
    z-index: 100000;
    background-color: #EEF0F8;
}

.topTitleSection {
    position: relative;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    margin-bottom: 1rem;
}

.unnotiLogo {
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: 160px;
}

.title {
    color: #0D2454;
    font-size: 55px;
    font-weight: bold;
    text-align: center;
    font-family: 'Nimbus', '-apple-system', 'BlinkMacSystemFont', "Segoe UI", "Roboto";
}

.wrapper {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 2rem;
}

.mapWrapper {
    grid-row: span 2;
    background-color: white;
    border-radius: 1rem;
    position: relative;
    box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.2);
    padding: 2rem;
}

.mapTitle {
    font-size: 32px;
    font-weight: bold;
    color: #052569;
    text-align: center;
    font-family: 'Nimbus', '-apple-system', 'BlinkMacSystemFont', "Segoe UI", "Roboto";
    margin-bottom: 15px;
}

.defaultMap {
    height: calc(100% - 80px);
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    z-index: 1;
}

.changedMap {
    height: calc(100% - 80px);
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    animation: blink 1s infinite;
}

@keyframes blink {
    from {z-index: 2;}
    to {z-index: 0;}
}

.card {
    background-color: white;
    border-radius: 1rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.2);
}

.progressWrapper svg {
    width: 280px;
    height: 280px;
}

.progressValue {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    user-select: none;
    color: white;
    font-size: 70px;
    font-weight: bold;
}

.cardTitle {
    font-size: 40px;
    font-weight: bold;
    font-family: 'Nimbus', '-apple-system', 'BlinkMacSystemFont', "Segoe UI", "Roboto";
    text-align: center;
}

.regColor {
    color: #052569;
}

.secondCard {
    background-color: #052569;
    color: white;
}

.value {
    font-size: 120px;
    font-weight: bold;
    color: #052569;
    text-shadow: -1px 1px 8px #0393D3,
				  1px 1px 8px #0393D3,
				  1px -1px 0 #0393D3,
				  -1px -1px 0 #0393D3;
                  height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1400px) {
    .unnotiLogo {
        width: 100px;
    }

    .title {
        font-size: 40px;
    }

    .cardTitle {
        font-size: 30px;
    }

    .value {
        font-size: 100px;
    }

    .progressWrapper svg {
        width: 180px;
        height: 180px;
    }

    .progressValue {
        font-size: 40px;
    }
}

@media (max-width: 576px) {
    .container {
        padding: 8px;
    }

    .unnotiLogo {
        width: 50px;
    }

    .title {
        font-size: 25px;
    }

    .wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    .mapWrapper {
        grid-row: span 1;
        height: 50vh;
    }

    .cardTitle {
        font-size: 22px;
    }

    .progressWrapper svg {
        width: 250px;
        height: 250px;
    }

    .progressValue {
        font-size: 60px;
    }
}