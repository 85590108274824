body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

footera {
  position: fixed;
  height: 100px;
  bottom: 0;
  width: 100%;
}

.test {
  border: 1px red;
}
.month-picker {
  position: relative; }
  .month-picker > .rmp-container {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 100;
    top: 1px;
    left: -10000px;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; }
    @media screen and (max-width: 767px) {
      .month-picker > .rmp-container {
        position: fixed;
        top: 0;
        left: -10000px;
        width: 100%;
        height: 100%;
        transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; } }
    .month-picker > .rmp-container.rmp-table {
      display: table; }
    .month-picker > .rmp-container.show {
      left: 0;
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
      .month-picker > .rmp-container.show .rmp-overlay {
        left: 0; }
    .month-picker > .rmp-container .rmp-overlay {
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: 9;
      top: 0;
      left: -10000px;
      opacity: 1;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      will-change: opacity;
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
      @media screen and (max-width: 767px) {
        .month-picker > .rmp-container .rmp-overlay {
          background-color: rgba(0, 0, 0, 0.25); } }
    .month-picker > .rmp-container .rmp-cell {
      display: table-cell;
      vertical-align: middle;
      box-sizing: border-box;
      width: 100%;
      height: 100%; }
      @media screen and (max-width: 767px) {
        .month-picker > .rmp-container .rmp-cell {
          vertical-align: bottom; } }
    .month-picker > .rmp-container .rmp-popup {
      position: absolute;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
      margin: 0 auto;
      z-index: 10;
      font-size: 1.2rem;
      opacity: 0;
      border-radius: 3px;
      padding: 0.4rem;
      box-sizing: content-box; }
      @media screen and (max-width: 767px) {
        .month-picker > .rmp-container .rmp-popup {
          box-sizing: border-box;
          bottom: 0;
          width: 100%;
          max-width: 100%;
          height: 14.4rem;
          -webkit-transform: translate3d(0, 14.4rem, 0);
                  transform: translate3d(0, 14.4rem, 0); }
          .month-picker > .rmp-container .rmp-popup.range {
            height: 28rem;
            -webkit-transform: translate3d(0, 28rem, 0);
                    transform: translate3d(0, 28rem, 0); }
            .month-picker > .rmp-container .rmp-popup.range .rmp-pad {
              margin-top: 0.4rem; }
              .month-picker > .rmp-container .rmp-popup.range .rmp-pad:first-of-type {
                margin-top: 0; } }
      @media screen and (min-width: 768px) {
        .month-picker > .rmp-container .rmp-popup {
          -webkit-transform: translate3d(0, -64px, 0);
                  transform: translate3d(0, -64px, 0);
          top: 0;
          width: 20rem; }
          .month-picker > .rmp-container .rmp-popup.range {
            width: 40.6rem;
            padding: 0.6rem; } }
      .month-picker > .rmp-container .rmp-popup.show {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0); }
      .month-picker > .rmp-container .rmp-popup:after {
        content: ' ';
        clear: both;
        display: table; }
      .month-picker > .rmp-container .rmp-popup .rmp-pad {
        position: relative; }
        @media screen and (min-width: 768px) {
          .month-picker > .rmp-container .rmp-popup .rmp-pad {
            box-sizing: border-box;
            float: left;
            width: 20rem; }
            .month-picker > .rmp-container .rmp-popup .rmp-pad:nth-of-type(2) {
              float: right; } }
        .month-picker > .rmp-container .rmp-popup .rmp-pad > div label {
          display: block;
          font-size: 1.4rem;
          text-align: center;
          line-height: 3.4rem; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div label b {
            font-weight: normal;
            margin-right: 0.5em; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
          font-style: normal;
          text-align: center;
          width: 3.4rem;
          height: 3.4rem;
          line-height: 3.4rem;
          position: absolute;
          top: 0; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div i.prev {
            left: 0; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div i.next {
            right: 0; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad ul, .month-picker > .rmp-container .rmp-popup .rmp-pad li {
          list-style-type: none;
          margin: 0;
          padding: 0; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad ul {
          display: block;
          width: 100%; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad ul:after {
            content: ' ';
            clear: both;
            display: table; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad li {
          display: block;
          float: left;
          text-align: center;
          font-size: 1.15rem;
          border-radius: 3px;
          line-height: 3.3rem;
          box-sizing: border-box;
          padding: 0.05rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          *white-space: nowrap;
          overflow: hidden;
          -webkit-font-smoothing: antialiased;
          -webkit-text-stroke-width: 0.2px;
          -moz-osx-font-smoothing: grayscale;
          transition: background-color 200ms ease-in-out, color 200ms ease-in-out; }
          @media screen and (max-width: 767px) {
            .month-picker > .rmp-container .rmp-popup .rmp-pad li {
              width: 25%; } }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup .rmp-pad li {
              width: 33.3333333333%; } }
          .month-picker > .rmp-container .rmp-popup .rmp-pad li.multiple {
            background-clip: content-box; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad li.range {
            border-radius: 1px; }
      .month-picker > .rmp-container .rmp-popup.light {
        color: #666;
        background-color: rgba(255, 255, 255, 0.96); }
        @media screen and (max-width: 767px) {
          .month-picker > .rmp-container .rmp-popup.light {
            border-top: 1px solid #ccc;
            box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.08); }
            .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
              border-top: 1px solid rgba(204, 204, 204, 0.5); }
              .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad:first-of-type {
                border-top: 0; } }
        @media screen and (min-width: 768px) {
          .month-picker > .rmp-container .rmp-popup.light {
            border: 1px solid #ccc;
            box-shadow: 0 1px 5px #ddd; }
            .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
              background-color: rgba(238, 238, 238, 0.9); } }
        .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn {
          cursor: pointer;
          moz-user-select: -moz-none;
          -o-user-select: none;
          -webkit-user-select: none;
          user-select: none; }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
              background-color: rgba(255, 227, 160, 0.59); } }
          .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn.select {
            background-color: #d3d3d3; }
        .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active:hover {
          background-color: rgba(31, 42, 58, 0.73);
          color: white; }
        .month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable:hover, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable:hover {
          background-color: transparent;
          color: #bbb;
          cursor: default; }
      .month-picker > .rmp-container .rmp-popup.dark {
        color: #fff;
        background-color: rgba(50, 50, 50, 0.96); }
        @media screen and (max-width: 767px) {
          .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
            border-top: 1px solid rgba(113, 113, 113, 0.41); }
            .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad:first-of-type {
              border-top: 0; } }
        @media screen and (min-width: 768px) {
          .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
            background-color: rgba(70, 70, 70, 0.9); } }
        .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn {
          cursor: pointer;
          moz-user-select: -moz-none;
          -o-user-select: none;
          -webkit-user-select: none;
          user-select: none; }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn:hover {
              background-color: rgba(255, 210, 96, 0.33); } }
          .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn.select {
            background-color: #262828; }
        .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active:hover {
          background-color: rgba(189, 211, 242, 0.7);
          color: #303030; }
        .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable:hover, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.disable, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.disable:hover {
          background-color: transparent;
          color: #717171;
          cursor: default; }

.month-picker > .box {
  cursor: pointer; }
  @media screen and (max-width: 767px) {
    .month-picker > .box {
      box-sizing: border-box;
      line-height: 5rem;
      font-size: 1.32rem;
      color: #777777;
      text-align: right; } }
  @media screen and (min-width: 768px) {
    .month-picker > .box {
      border: 1px solid #e0e0e0;
      background-color: #f6f6f6;
      padding-left: 0.9rem;
      box-sizing: border-box;
      line-height: 3.2rem;
      font-size: 1.3rem;
      color: #525252; }
      .month-picker > .box > label {
        font-size: 1.3rem;
        color: #525252; } }

.month-picker .tab.btn {
  font-size: 0; }
  .month-picker .tab.btn:before {
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-family: "icomoon";
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    font-size: 1.8rem;
    line-height: 3.4rem;
    text-align: center;
    width: 3.4rem; }
  .month-picker .tab.btn.prev:before {
    content: ""; }
  .month-picker .tab.btn.next:before {
    content: ""; }

.years-panel {
  position: fixed;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: -10000px;
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; }
  .years-panel.table {
    display: table; }
  .years-panel.show {
    left: 0;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
  .years-panel.show > .overlay {
    left: 0;
    background-color: rgba(82, 82, 82, 0.49); }
  .years-panel > .overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
    top: 0;
    left: -10000px;
    opacity: 1;
    -webkit-tap-highlight-color: transparent;
    will-change: opacity;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
  .years-panel .cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%; }
  .years-panel .popup {
    display: inline-block;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
    margin: 0 auto;
    z-index: 10;
    font-size: 1.2rem;
    opacity: 0;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.81);
    padding: 1.8rem; }
    .years-panel .popup textarea {
      outline: none;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 1.5rem;
      width: 20rem;
      line-height: 150%;
      padding: 1rem; }
    .years-panel .popup:after {
      content: ' ';
      clear: both;
      display: table; }
  .years-panel.show .popup {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }

.list-area {
  margin-top: 5rem; }
  @media screen and (min-width: 768px) {
    .list-area {
      width: 500px;
      margin-left: auto;
      margin-right: auto; } }
  @media screen and (max-width: 767px) {
    .list-area > ul {
      border-bottom: 1px solid #e8e8e8; }
      .list-area > ul > li {
        position: relative;
        background-color: #fff;
        border-top: 1px solid #e8e8e8;
        padding: 0 1.6rem; }
        .list-area > ul > li:after {
          content: ' ';
          clear: both;
          display: table; }
        .list-area > ul > li > label {
          float: left;
          display: block;
          line-height: 5rem;
          color: #440f24;
          font-size: 1.12rem; }
          .list-area > ul > li > label b {
            font-size: 1.1em; }
          .list-area > ul > li > label span {
            position: absolute;
            display: block;
            font-size: 0.8rem;
            color: #bfbfbf;
            left: 1.6rem;
            top: 1.5em; }
            .list-area > ul > li > label span i {
              display: inline-block;
              font-style: normal; }
              .list-area > ul > li > label span i.sub-btn {
                display: none; }
        .list-area > ul > li .edit {
          margin-left: 9rem;
          line-height: 5rem; } }
  @media screen and (min-width: 768px) {
    .list-area > ul {
      padding: 1.8rem; }
      .list-area > ul > li {
        padding: 0.5rem 0 1.4rem;
        font-size: 1.3rem; }
        .list-area > ul > li:after {
          content: ' ';
          clear: both;
          display: table; }
        .list-area > ul > li > label {
          display: block;
          margin-right: 1rem;
          padding: 1px;
          color: #898989;
          font-size: 1.1rem;
          margin-bottom: 0.5em; }
          .list-area > ul > li > label b {
            display: block;
            font-size: 1.1em; }
          .list-area > ul > li > label span {
            display: block;
            font-size: 0.9em;
            color: #afafaf;
            margin-top: 0.4em; }
            .list-area > ul > li > label span i {
              display: inline-block;
              font-style: normal; }
              .list-area > ul > li > label span i.sub-btn {
                padding: 0.1rem 0.3rem;
                margin-left: 1.5rem;
                border-radius: 3px;
                background-color: #696969;
                color: white;
                font-size: 0.8em;
                cursor: pointer; }
        .list-area > ul > li .edit {
          padding: 1px;
          height: 3.3rem; } }

          ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0; }

  .MuiFormControl-root{
      width: 100%;
  }

  .customModalBody {
        padding: 0 !important;
    }
 .MuiTableCell-head{
     font-weight: bolder !important;
 }

 .modal{
     z-index: 9999;
 }

 .footer {
    height: 60px;
    z-index: 97;
    box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.13);
    bottom: 0;
    right: 0;
    width: 100%;
}

.react-confirm-alert-body {
    font-family: Arial, Helvetica, sans-serif;
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #cee6ff !important;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
    color: #666;
}

.swal-icon:first-child {
    margin-top: -7px;
    margin-bottom: -7px;
}
.swal-modal{
    width: 9%;
    z-index: 99999;
    background: transparent;
}

.pointer-none{
  cursor: not-allowed;
  pointer-events: none !important;
  background-color: #EEE;
}

#__react-alert__{
    z-index: 999999;
}
.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.select__menu {
  text-align: left;
  text-align-last: left;
  /* webkit*/
}

/* for styling selection dropdown */
.dashboard-selection .dropdown-container {
  padding: 5px 4px;
  color: black;
  background-color: #ECEEEF !important;
  border-top-right-radius: 0.8rem !important;
  border-bottom-right-radius: 0.8rem !important;
  border: none !important;
  outline: none !important;
  border-style: none !important;
  max-width: 100px !important;
}

.dashboard-selection .dropdown-heading {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.dashboard-selection .dropdown-content {
  min-width: 165px;
  right: 0;
}

/* daterange custom design */
.react-daterange-picker__wrapper {
  padding: 3.5px;
  border-radius: 0.8rem;
  border: none !important;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.leaderboard-dateRange .react-daterange-picker__wrapper {
  border: none !important;
  background: transparent;
  box-shadow: none;
  color: white;
}

.leaderboard-dateRange .react-daterange-picker__button svg {
  stroke: white;
}

.leaderboard-dateRange .react-daterange-picker__wrapper input {
  color: white;
}

.custom-multiselect .dropdown-container{
  max-width: 350px !important;
  width: 350px !important;
}

@media (max-width: 1500px) {
  .dashboard-selection .dropdown-container {
    max-width: 95px !important;
  }

  .custom-multiselect .dropdown-container{
    max-width: 267px !important;
    width: 267px !important;
  }
}

@media screen and (min-width:577px) and (max-width:1280px) and (orientation:landscape) {
  .dashboard-selection .dropdown-container {
    max-width: 45px !important;
  }
}

@media (max-width: 576px) {
  .dashboard-selection .dropdown-container {
    max-width: 100% !important;
  }
}

/* react customizable progressbar */
.RCP {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}


/* custom scroll-bar */
.custom-scrollbar::-webkit-scrollbar {
  width: 7px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #1E519F;
  border: 5px solid transparent;
  background-clip: content-box;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #1E519F;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #0092D2;
}

@font-face {
  font-family: "Nimbus";
  src: local("Nimbus"),
    url(/static/media/Nimbus.69819712.otf) format("opentype");
}
.sidebar_logoutBtnWrapper__3Nnzz {
    display: grid;
    place-content: center;
}

.sidebar_btnWrapper__3fENk {
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    color: white;
    padding: 0.5rem 2rem;
    background: linear-gradient(to bottom, #059EDA, #3C62AE);
}

.sidebar_btnWrapper__3fENk:hover {
    color: white;
}
.topDropdownWithPhase_filterWrapper__M47nN {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

@media (max-width: 1500px) {
    .topDropdownWithPhase_filterWrapper__M47nN {
        flex-wrap: wrap;
    }
}
.crLimitConfig_fiInputWrapper__2KFhA {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 1rem;
    align-items: flex-end;
}
.insertInterest_fiInputWrapper__szsra {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 1rem;
    align-items: flex-end;
}
.multiselect_wrapper__9wW_N {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  font-size: 11px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  border-radius: 0.8rem;
}

.multiselect_label__1x8pe {
  padding: 5px;
  background-color: white;
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
  display: flex;
  align-items: center;
}

.multiselect_error__31_w5 {
  font-size: 11px;
  color: red;
  margin-top: 0.25rem;
}

.multiselect_input__VLGn-{
    z-index: 9999;
}

.multiselect_rmsc__VLOQT {
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 38px; /* Height */
}

@media (max-width: 576px) {
  .multiselect_wrapper__9wW_N {
    width: 100%;
    grid-template-columns: 1fr 3fr;
  }

  .multiselect_input__VLGn-{
    z-index: unset;
}
}
.firstSection_wrapper__1kcTK {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-gap: 2rem;
  margin-bottom: 2rem;
}

.firstSection_cardView__2lfwD {
  border-radius: 1rem;
}

.firstSection_totalOutletWrapper__3eFYF {
  grid-column: span 5;
  width: 99%;
}

.firstSection_otherCardWrapper__CIf6- {
  grid-column: span 2;
  width: 23.5%;
  position: absolute;
  left: 46%;
}

.firstSection_lastCardWrapper__2Oh9U {
  grid-column: span 4;
  width: 28%;
  position: absolute;
  left: 72%;    
}

.firstSection_titleWrapper__2oFjp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 1rem;
  background: linear-gradient(to bottom, #059EDA, #3C62AE);
  color: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  font-weight: bold;
}

.firstSection_cardContent__1AzS0 {
  padding: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  background-color: white;
}

.firstSection_progressContainer__98GlO {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.firstSection_progressWrapper__14qME {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.firstSection_progressWrapper__14qME svg {
  max-width: 76px;
}

.firstSection_progressTitle__2xLxE {
  font-weight: bold;
}

.firstSection_totalValue__382nl {
  color: #059EDA;
  font-size: rfs(6.2rem);
  font-weight: bold;
  line-height: 1.1;
}

@media (max-width: 576px) {
  .firstSection_wrapper__1kcTK {
    grid-template-columns: 1fr;
    margin-bottom: 3rem;
  }
}

.rightSection_wrapper__1J5J2 {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 2rem;
}

.rightSection_leftSection__2xWcB {
  grid-column: span 5;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.rightSection_cardView__Z8vi9 {
  display: grid;
  grid-template-rows: auto 1fr;
}

.rightSection_titleWrapper__17nNY {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 1rem;
  background: linear-gradient(to bottom, #059EDA, #3C62AE);
  color: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  font-weight: bold;
}

.rightSection_cardContent__2kW-N {
  padding: 5px 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  background-color: white;
}

.rightSection_perItem__1DEuh {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 5px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #b3b3b3;
  padding: 10px 0;
}

.rightSection_perItem__1DEuh:last-child {
  border-bottom: none;
}

.rightSection_key__2MZ17 {
  font-size: rfs(2rem);
}

.rightSection_icon__25LXQ {
  width: 30px;
}

.rightSection_value__1EGyw {
  color: #059EDA;
  font-size: rfs(3.5rem);
  font-weight: bold;
  line-height: 1.1;
  justify-self: flex-end;
}

.rightSection_rightSection__1I2W6 {
  grid-column: span 3;
  text-align: center;
  display: grid;
  grid-template-rows: auto 1fr;
}

.rightSection_rightSection__1I2W6 .rightSection_cardContent__2kW-N {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
}

/* @media (max-width: 1600px) {
  .progressWrapper svg {
    max-width: 100px;
    margin: 0 auto;
  }
} */

@media (max-width: 576px) {
  .rightSection_wrapper__1J5J2 {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 3rem;
  }
}


.secondSection_wrapper__2_cMH {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 2rem;
  margin-bottom: 3rem;
  grid-auto-rows: 1fr;
}

.secondSection_leftSection__2mO6j {
  grid-column: span 2;
  flex-direction: column;
  display: grid;
  grid-template-rows: auto 1fr;
}

.secondSection_titleWrapper__3U5Z0 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 1rem;
  background: linear-gradient(to bottom, #059EDA, #3C62AE);
  color: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  font-weight: bold;
}

.secondSection_cardContent__3uEOm {
  padding: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  background-color: white;
}

.secondSection_bigPercentage__R1ptk {
  font-size: rfs(4.65rem);
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
}

.secondSection_progressContainer__Q7u-f {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.secondSection_progressWrapper__2nYTa {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.secondSection_progressWrapper__2nYTa svg {
  max-width: 100px;
}

.secondSection_rightSection__19dRx {
  grid-column: span 8;
}

.secondSection_progressWrapper__2nYTa {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
}

.secondSection_progressWrapper__2nYTa:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.secondSection_boldText__VsGr8 {
  font-weight: bold;
}

.secondSection_progressTitle__-DL3G {
  margin-bottom: 10%;
}

.secondSection_progressWrapper__2nYTa svg {
  max-width: 90px;
  margin: 0 auto;
}

@media (max-width: 576px) {
  .secondSection_wrapper__2_cMH {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 3rem;
  }
}


.map_wrapper__32bvZ {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 2rem;
  margin-bottom: 3rem;
  grid-auto-rows: 1fr;
}

.map_titleWrapper__24ByU {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 1rem;
  background: linear-gradient(to bottom, #059EDA, #3C62AE);
  color: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  font-weight: bold;
}

.map_leftSection__f9xHJ {
  grid-column: span 12;
  flex-direction: column;
  display: grid;
  grid-template-rows: auto 1fr;
}

@media (max-width: 576px) {
  .map_wrapper__32bvZ {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 3rem;
  }
}


.input_wrapper__1GzvW {
}

.input_label__3iwwO {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.input_input__AqOiE {
  /* display: block; */
  width: 100%;
  min-width: 200px;
  display: block;
  background-color: white;
  border-radius: 0.8rem;
  outline: none;
  padding: 0 10px;
}

.dashboard_container__tl9EH {
  min-height: 100vh;
  padding-bottom: 5%;
  background-color: #F0F3F4;
}

.dashboard_input__1Yx9A {
  /* display: block; */
  width: 100%;
  min-width: 200px;
  display: block;
  background-color: white;
  border-radius: 0.8rem;
  outline: none;
  padding: 0 10px;
}

.dashboard_topBar__Hx82d {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 100;
}

.dashboard_flexSection__DBYiM {
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard_logoSection__3Kd6q {
  display: flex;
  align-items: center;
}

.dashboard_logo__1Bq4n {
  width: 100px;
}

.dashboard_dashboardText__vJXML {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: rfs(6.2rem);
  font-weight: bold;
  text-transform: uppercase;
  background: linear-gradient(to bottom, #059EDA, #3C62AE);
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 0.8;
  padding-left: 20%;
}

.dashboard_dashboardH__37TJY {
  font-weight: 1000;
    background: -webkit-linear-gradient(#3A65B0, #079CD8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Roboto';
}

.dashboard_userDateWrapper__DYq7j {
  display: flex;
  align-items: center;
}

.dashboard_icon__13f-e {
  width: 50px;
    left: 20%;
    position: absolute;
}

.dashboard_dateWrapper__15wms .dashboard_react-daterange-picker__2qqqA {
  border-radius: 0.8rem;
}

.dashboard_dateWrapper__15wms {
  margin-right: 3rem;
  border: 1px solid;
  border-image-slice: 1;
  border-radius: 0.5rem;
  border-image-source: linear-gradient(to bottom, #059EDA, #3C62AE);
  padding: 1rem;
  padding-top: 1.2rem;
  position: relative;
}

.dashboard_dateText__3MWNY {
  width: 9rem;
  position: absolute;
  top: -0.7rem;
  left: 1rem;
  background-color: white;
  font-size: rfs(1rem);
  text-align: center;
}

.dashboard_liveWrapper__3_2fS {
  position: fixed;
  top: 1rem;
  right: 1rem;
  width: 40px;
  height: 40px;
  border: 5px double;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard_liveWrapperAnimate__3Lpxa {
  position: fixed;
  top: 1rem;
  right: 1rem;
}

.dashboard_ribonWrapper__1FcjR {
  height: 12px;
  width: 100%;
  display: flex;
}

.dashboard_contentWrapper__1cdzE {
  height: 100%;
  padding: 0 8vw;
  padding-top: 6.5rem;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

.dashboard_bottomLogo__3_o8b {
    display:none;
  position: absolute;
  top: calc(100vh - 110px - 80px);
  left: 0;
  width: 110px;
  opacity: 0.5;
}

.dashboard_filterSectionWrapper__1nXq3 {
  margin-bottom: 1.5rem;
}

.dashboard_selectionWrapper__mAplf {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 0.5rem;
  justify-content: space-evenly;
  align-items: flex-end;
}

.dashboard_submitButton__1dVtz {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-style: none;
  padding: 4px 2rem;
  border-radius: 0.8rem;
  background: linear-gradient(to bottom, #059EDA, #3C62AE);
  color: white;
}

.dashboard_logoutBtn__1OjmX {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  border-style: none;
  background-color: transparent;
  color: white;
}

.dashboard_name__21ZEk {
  font-size: 80px;
}

.dashboard_circleOuter__1ymB2 {
  border: 1px solid #EC3463;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  opacity: .8;
  -webkit-animation: dashboard_circle__1B2ss 2s ease-in-out infinite;
  animation: dashboard_circle__1B2ss 2s ease-in-out infinite;
}

.dashboard_circleInner__3OK9z {
  background: #EC3463;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  opacity: .8;
}

.dashboard_circleInner__3OK9z:after {
  content: '';
  display: block;
  border: 2px solid #EC3463;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  top: -6px;
  left: -6px;
  position: absolute;
  opacity: .8;
  -webkit-animation: dashboard_circle__1B2ss 2s ease-in-out .2s infinite;
  animation: dashboard_circle__1B2ss 2s ease-in-out .2s infinite;
}


@-webkit-keyframes dashboard_circle__1B2ss {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes dashboard_circle__1B2ss {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

.dashboard_circle__1B2ss {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #EC3463;
}

@media (max-width: 1600px) {
  .dashboard_contentWrapper__1cdzE {
    padding: 0 3vw;
    padding-top: 6.5rem;
  }
}

@media (max-width: 576px) {
  .dashboard_topBar__Hx82d {
    padding: 0.5rem 5vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .dashboard_logoSection__3Kd6q {
    margin-bottom: 1rem;
  }

  .dashboard_logo__1Bq4n {
    width: 80px;
  }

  .dashboard_userDateWrapper__DYq7j {
    width: 100%;
    justify-content: space-between;
  }

  .dashboard_dateWrapper__15wms {
    margin-right: 0;
    padding: 1rem;
    padding-top: 1.2rem;
    position: relative;
  }

  .dashboard_userImage__2Vj3W {
    width: 3.5rem;
    height: 3.5rem;
  }

  .dashboard_contentWrapper__1cdzE {
    padding: 0 8px;
    padding-top: 11rem;
  }

  .dashboard_selectionWrapper__mAplf {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    justify-content: space-evenly;
  }

  .dashboard_liveWrapperAnimate__3Lpxa {
    position: fixed;
    top: 4.5rem;
    right: 0.5rem;
  }

  .dashboard_liveWrapper__3_2fS {
    top: 4.5rem;
    right: 0.5rem;
    z-index: 101;
  }

  .dashboard_circle__1B2ss {
    width: 8px;
    height: 8px;
  }
}

.dashboard_dropdown-content__34osJ{
    z-index: 99999;
}
.leftSection_wrapper__ncgXt {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
}

.leftSection_cardView__D99po {
  padding: 1rem 2rem;
  border-radius: 1rem;
  background-color: white;
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftSection_topCardContent__2A0Fm {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftSection_progressWrapper__3WAZ1 {
  margin-left: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leftSection_progressWrapper__3WAZ1 svg {
  width: 160px;
  height: 160px;
}

.leftSection_progressValue__aDlur {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 95px;
  height: 95px;
  border-radius: 100%;
  margin: auto;
  -webkit-user-select: none;
          user-select: none;
  background-color: #1E519F;
  color: white;
  font-size: 2.3rem;
  font-weight: bold;
}

.leftSection_text__2dptg {
  color: black;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.2;
}

.leftSection_map__37B2a {
  width: 80%;
}

@media (max-width: 1500px) {
  .leftSection_wrapper__ncgXt {
    grid-gap: 1rem;
  }

  .leftSection_cardView__D99po {
    padding: 1rem;
  }

  .leftSection_progressWrapper__3WAZ1 {
    margin-left: 0;
  }

  .leftSection_progressWrapper__3WAZ1 svg {
    width: 120px;
    height: 120px;
  }

  .leftSection_progressValue__aDlur {
    width: 75px;
    height: 75px;
    font-size: 1.8rem;
  }

  .leftSection_text__2dptg {
    font-size: 1rem;
    line-height: 1.2;
  }

  .leftSection_map__37B2a {
    width: 95%;
  }
}

@media screen and (min-width:577px) and (max-width:850px) and (orientation:portrait) {
  .leftSection_wrapper__ncgXt {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .leftSection_progressValue__aDlur {
    font-size: 1.5rem;
  }

  .leftSection_text__2dptg {
    font-size: 0.7rem;
  }
}

@media (max-width: 576px) {
  
}


.bottomSection_wrapper__31P0H {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.bottomSection_leftSection__27ewu {
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 2rem;
}

.bottomSection_cardView__28G__ {
  padding: 1rem 2rem;
  border-radius: 1rem;
  background-color: white;
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.2);
}

.bottomSection_chartTitle__1ZtD3 {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 0.5rem;
}

.bottomSection_circularCardView__3fAIm {
  padding: 1rem;
}

.bottomSection_creditUtilizationWrapper__1V3KV {
  display: flex;
  justify-content: space-evenly;
}

.bottomSection_progressWrapper__1qA3z {
  flex: 1 1;
  text-align: center;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottomSection_progressWrapper__1qA3z svg {
  width: 120px;
  height: 120px;
}

.bottomSection_progressValue__vZO7L {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  -webkit-user-select: none;
          user-select: none;
  color: #1E519F;
  font-size: 2.3rem;
  font-weight: bold;
}

.bottomSection_rightSection__C7FZy {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 2rem;
}

.bottomSection_rightCardContent__3BbFh {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
}

.bottomSection_titleIcon__zoVz- {
  margin-bottom: 5px;
}

.bottomSection_sectionTitle__2Dh_e {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.2;
}

.bottomSection_amountWrapper__2TfEy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: black;
}

.bottomSection_totalAmount__3AGGK {
  color: #1E519F;
  font-size: 2.3rem;
  font-weight: bold;
  line-height: 1.2;
}

.bottomSection_secondaryAmount__1ufnA {
  font-size: 2.3rem;
  font-weight: bold;
  color: #0092D2;
  line-height: 1.2;
  text-align: center;
}

.bottomSection_boldText__id-HZ {
  font-weight: bold;
}

.bottomSection_line__3kE6Y {
  height: 80px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.2);
}

.bottomSection_outletAmountWrapper__16Aa- {
  display: flex;
  flex-direction: column;
}

.bottomSection_perItem__1mR4x {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 1rem 0;
  color: black;
}

.bottomSection_horizontalLine__2aLdZ {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.bottomSection_sectionTitleSmallDevice__1MJk6 {
  display: none;
}

.bottomSection_unnotiLogo__3za34 {
  position: absolute;
  right: 0;
  bottom: -50px;
  width: 250px;
}

@media (max-width: 1500px) {
  .bottomSection_wrapper__31P0H {
    grid-gap: 1rem;
  }

  .bottomSection_leftSection__27ewu {
    grid-gap: 1rem;
  }

  .bottomSection_cardView__28G__ {
    padding: 1rem;
  }

  .bottomSection_progressWrapper__1qA3z svg {
    width: 90px;
    height: 90px;
  }

  .bottomSection_progressValue__vZO7L {
    font-size: 1.6rem;
  }

  .bottomSection_rightSection__C7FZy {
    grid-gap: 1rem;
  }

  .bottomSection_rightCardContent__3BbFh {
    grid-template-columns: 1fr;
  }

  .bottomSection_sectionTitleSmallDevice__1MJk6 {
    display: block;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 10px;
    text-align: center;
  }

  .bottomSection_sectionTitle__2Dh_e {
    display: none;
  }

  .bottomSection_totalAmount__3AGGK {
    font-size: 2rem;
  }
  
  .bottomSection_secondaryAmount__1ufnA {
    font-size: 2rem;
  }

  .bottomSection_line__3kE6Y {
    height: 60px;
  }

  .bottomSection_perItem__1mR4x {
    padding: 10px 0;
  }

  .bottomSection_unnotiLogo__3za34 {
    position: absolute;
    right: 0;
    bottom: -90px;
    width: 200px;
  }
}

@media screen and (min-width:577px) and (max-width:1280px) and (orientation:landscape) {
  .bottomSection_chartTitle__1ZtD3 {
    font-size: 1rem;
  }

  .bottomSection_progressValue__vZO7L {
    font-size: 1.5rem;
  }

  .bottomSection_sectionTitleSmallDevice__1MJk6 {
    font-size: 1rem;
  }

  .bottomSection_totalAmount__3AGGK {
    font-size: 1.6rem;
  }

  .bottomSection_secondaryAmount__1ufnA {
    font-size: 1.3rem;
  }

  .bottomSection_rightSection__C7FZy .bottomSection_secondaryAmount__1ufnA {
    font-size: 1.6rem;
  }

  .bottomSection_amountWrapper__2TfEy {
    justify-content: space-evenly;
  }
}

@media screen and (min-width:577px) and (max-width:850px) and (orientation:portrait) {
  .bottomSection_wrapper__31P0H {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }
  
  .bottomSection_leftSection__27ewu {
    grid-template-columns: 1fr 1fr;
  }

  .bottomSection_rightSection__C7FZy {
    grid-template-columns: 1fr 1fr;
  }

  .bottomSection_rightCardContent__3BbFh {
    height: 100%;
  }

  .bottomSection_chartTitle__1ZtD3 {
    font-size: 1rem;
  }

  .bottomSection_progressValue__vZO7L {
    font-size: 1.6rem;
  }

  .bottomSection_sectionTitleSmallDevice__1MJk6 {
    font-size: 1rem;
  }

  .bottomSection_totalAmount__3AGGK {
    font-size: 1.6rem;
  }

  .bottomSection_secondaryAmount__1ufnA {
    font-size: 1.3rem;
  }

  .bottomSection_rightSection__C7FZy .bottomSection_totalAmount__3AGGK {
    font-size: 2rem;
  }

  .bottomSection_rightSection__C7FZy .bottomSection_secondaryAmount__1ufnA {
    font-size: 2rem;
  }

  .bottomSection_amountWrapper__2TfEy {
    justify-content: space-evenly;
  }
}

@media (max-width: 576px) {
  .bottomSection_wrapper__31P0H {
    grid-template-columns: 1fr;
  }

  .bottomSection_unnotiLogo__3za34 {
    right: auto;
    left: -60px;
    bottom: -120px;
    width: 150px;
    z-index: -1;
  }
}




.rightSection_topSection__iBsir {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin-bottom: 2rem;
}

.rightSection_cardView__3qRcf {
  padding: 1rem 2rem;
  border-radius: 1rem;
  background-color: white;
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1.5rem;
}

.rightSection_disbursementWrapper__z9H19 {
  color: black;
}

.rightSection_todayDisbursement__1Aadc {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.rightSection_title__1ODVA {
  margin-bottom: 5px;
}

.rightSection_amountWrapper__2RI1F {
  display: grid;
  grid-template-columns: 1fr 1fr 0.6fr;
  grid-gap: 5px;
}

.rightSection_totalAmount__1KE29 {
  color: #1E519F;
  font-size: 2.3rem;
  font-weight: bold;
  line-height: 1;
}

.rightSection_secondaryAmount__2G3Bt {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1;
}

.rightSection_secondaryTextColor__5qq4x {
  color: #0092D2;
}

.rightSection_collectionWrapper__1tLRN {
  color: white;
  background-color: #1E519F;
}

.rightSection_todayCollection__2Z3hL {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(85, 222, 240, 0.8);
}

.rightSection_totalColorCollection__11oe3 {
  color: #55DEF0;
}

@media (max-width: 1500px) {
  .rightSection_topSection__iBsir {
    grid-gap: 1rem;
    margin-bottom: 1rem;
  }

  .rightSection_cardView__3qRcf {
    padding: 1rem;
    grid-gap: 1.5rem;
  }

  .rightSection_todayDisbursement__1Aadc {
    padding-bottom: 1rem;
  }

  .rightSection_todayCollection__2Z3hL {
    padding-bottom: 1rem;
  }

  .rightSection_totalAmount__1KE29 {
    font-size: 2rem;
  }

  .rightSection_secondaryAmount__2G3Bt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width:577px) and (max-width:1280px) and (orientation:landscape) {
  .rightSection_totalAmount__1KE29 {
    font-size: 1.6rem;
  }

  .rightSection_secondaryAmount__2G3Bt {
    font-size: 1.3rem;
  }
}

@media screen and (min-width:577px) and (max-width:850px) and (orientation:portrait) {
  .rightSection_totalAmount__1KE29 {
    font-size: 1.6rem;
  }

  .rightSection_secondaryAmount__2G3Bt {
    font-size: 1.3rem;
  }
}

@media (max-width: 576px) {
  .rightSection_topSection__iBsir {
    grid-template-columns: 1fr;
  }
}

.rightSection_chartTitle__IqdhK {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 0.5rem;
}
.liveIcon_liveWrapperAnimate__2AEQt {
  position: fixed;
  top: 5px;
  right: 2rem;
}

.liveIcon_circleOuter__1spRV {
  border: 1px solid #EC3463;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;
  opacity: .8;
  -webkit-animation: liveIcon_circle__33tZA 2s ease-in-out infinite;
  animation: liveIcon_circle__33tZA 2s ease-in-out infinite;
}

.liveIcon_circleInner__1WTYP {
  background: #EC3463;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  opacity: .8;
}

.liveIcon_circleInner__1WTYP:after {
  content: '';
  display: block;
  border: 2px solid #EC3463;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  top: -4px;
  left: -4px;
  position: absolute;
  opacity: .8;
  -webkit-animation: liveIcon_circle__33tZA 2s ease-in-out .2s infinite;
  animation: liveIcon_circle__33tZA 2s ease-in-out .2s infinite;
}


@-webkit-keyframes liveIcon_circle__33tZA {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes liveIcon_circle__33tZA {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

.liveIcon_liveWrapper__3O9Z7 {
  position: fixed;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  border: 5px double;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.liveIcon_circle__33tZA {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #EC3463;
}

@media (max-width: 1500px) {
  .liveIcon_liveWrapperAnimate__2AEQt {
    position: fixed;
    top: 4rem;
    right: 5px;
  }

  .liveIcon_liveWrapper__3O9Z7 {
    top: 4rem;
    right: 5px;
  }
}

@media screen and (min-width:577px) and (max-width:1280px) and (orientation:landscape) {
  .liveIcon_liveWrapperAnimate__2AEQt {
    position: fixed;
    top: 1rem;
    right: 5px;
  }

  .liveIcon_liveWrapper__3O9Z7 {
    top: 1rem;
    right: 5px;
  }
}

@media (max-width: 576px) {
  .liveIcon_liveWrapperAnimate__2AEQt {
    position: absolute;
    top: 1rem;
    right: 5px;
  }
}
.dashboard_container__1_BcU {
  min-height: calc(100vh - 65px);
  color: #1E519F;
  background-color: #F5F7F9;
  text-transform: uppercase;
  font-size: 12px;
}

.dashboard_topBar__wVDwF {
  width: calc(100% - 70px);
  position: fixed;
  top: 0;
  left: 70px;
  background-color: #F5F7F9;
  padding: 0.5rem 1rem 1rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.dashboard_filterSectionMobile__pm3Zo {
  display: none;
}

.dashboard_logoMenuWrapper__3V4BX {
  display: flex;
  align-items: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.dashboard_mobileMenuIcon__1Ccr6 {
  display: none;
}

.dashboard_logo__39vRB {
  width: 150px;
  height: 75px;
}

.dashboard_selectionWrapper__96Ux8 {
  width: 100%;
  display: flex;
  align-items: flex-end;
  grid-gap: 1rem;
  gap: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 1rem;
}

.dashboard_submitButton__2wlo5 {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-style: none;
  padding: 8.5px 1rem;
  border-radius: 0.8rem;
  background: linear-gradient(to bottom, #059EDA, #3C62AE);
  color: white;
}

.dashboard_contentWrapper__3kBc6 {
  height: 100%;
  padding: 0 1rem;
  padding-top: 3.5rem;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 2rem;
}

.dashboard_leftSection__Q9p0u {
  grid-column: span 4;
}

.dashboard_rightSection__2drt_ {
  grid-column: span 8;
}

@media (max-width: 1500px) {
  .dashboard_container__1_BcU {
    padding-bottom: 1rem;
  }

  .dashboard_contentWrapper__3kBc6 {
    padding: 0 1rem;
    padding-top: 4.2rem;
    grid-gap: 1rem;
    grid-template-columns: 1fr 3fr;
  }

  .dashboard_topBar__wVDwF {
    padding: 0.5rem 1rem 1rem 1rem;
  }

  .dashboard_logo__39vRB {
    width: 130px;
    margin-right: 0.5rem;
    height: 70px;
  }

  .dashboard_selectionWrapper__96Ux8 {
    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding-bottom: 1rem;
  }

  .dashboard_submitButton__2wlo5 {
    padding: 8.5px 1rem;
    margin-top: 7px;
    margin-right: 2rem;
  }

  .dashboard_leftSection__Q9p0u {
    grid-column: span 1;
  }
  
  .dashboard_rightSection__2drt_ {
    grid-column: span 1;
  }
}

@media screen and (min-width:577px) and (max-width:1280px) and (orientation:landscape) {
  .dashboard_container__1_BcU {
    font-size: 11px;
  }

  .dashboard_topBar__wVDwF {
    width: 100%;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .dashboard_mobileMenuIcon__1Ccr6 {
    display: block;
    margin-left: 1rem;
  }

  .dashboard_logo__39vRB {
    width: 100px;
    margin-right: 0;
    margin-left: 0;
    height: 54px;
  }

  .dashboard_filterSectionDesktop__WH8lE {
    width: 100%;
  }

  .dashboard_selectionWrapper__96Ux8 {
    grid-gap: 1rem;
    gap: 1rem;
    padding-bottom: 1rem;
  }

  .dashboard_submitButton__2wlo5 {
    margin-top: 0;
    margin-right: 0;
  }

  .dashboard_contentWrapper__3kBc6 {
    padding-top: 7.5rem;
  }
}


@media screen and (min-width:577px) and (max-width:850px) and (orientation:portrait) {
  .dashboard_container__1_BcU {
    font-size: 11px;
  }
  
  .dashboard_topBar__wVDwF {
    width: 100%;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .dashboard_filterSectionDesktop__WH8lE {
    display: none;
  }

  .dashboard_filterSectionMobile__pm3Zo {
    display: block;
  }

  .dashboard_mobileMenuIcon__1Ccr6 {
    display: block;
  }

  .dashboard_logo__39vRB {
    width: 100px;
    margin-right: 0;
    height: 54px;
  }

  .dashboard_selectionWrapper__96Ux8 {
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
    padding-bottom: 1rem;

  }

  .dashboard_submitButton__2wlo5 {
    margin-top: 0;
    margin-right: 0;
  }

  .dashboard_contentWrapper__3kBc6 {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-top: 40px;
    padding-bottom: 10rem;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }

  .dashboard_leftSection__Q9p0u {
    grid-column: span 1;
    order: 2;
  }
  
  .dashboard_rightSection__2drt_ {
    grid-column: span 1;
  }
}

@media (max-width: 576px) {
  .dashboard_topBar__wVDwF {
    width: 100%;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .dashboard_filterSectionDesktop__WH8lE {
    display: none;
  }

  .dashboard_filterSectionMobile__pm3Zo {
    display: block;
  }

  .dashboard_mobileMenuIcon__1Ccr6 {
    display: block;
  }

  .dashboard_logo__39vRB {
    width: 100px;
    margin-right: 0;
    height: 54px;
  }

  .dashboard_selectionWrapper__96Ux8 {
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
    padding-bottom: 1rem;

  }

  .dashboard_submitButton__2wlo5 {
    margin-top: 0;
    margin-right: 0;
  }

  .dashboard_contentWrapper__3kBc6 {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-top: 40px;
    padding-bottom: 10rem;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }

  .dashboard_leftSection__Q9p0u {
    grid-column: span 1;
  }
  
  .dashboard_rightSection__2drt_ {
    grid-column: span 1;
  }
}

.topDropdownBill_selectInput__3aSsj {
    width: 100%;
    padding: 5px 15px;
    border-radius: 4px;
    outline: none;
}
.billClaim_generatedContent__3TdOu {
    padding-top: 1rem;
}

.billClaim_valueText__pnyaO {
    font-weight: bold;
}

.billClaim_tableWrapper__269Vc {
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.billClaim_buttonWrapper__m4sP_ {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
}

.billClaim_button__Cc7p9 {
    color: white;
    background-color: #3f51b5;
    padding: 6px 15px;
    min-width: 140px;
    border-radius: 4px;
    border-style: none;
    text-transform: uppercase;
}

.billClaim_downloadBtn__1hcJL {
    cursor: pointer;
    color: #1BC5BD;
}
.supportOutlet_generatedContent__37JrT {
    padding-top: 1rem;
}

.supportOutlet_valueText__2e_q1 {
    font-weight: bold;
}

.supportOutlet_tableWrapper__2qihF {
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.supportOutlet_buttonWrapper__IxjHS {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
}

.supportOutlet_button__2lG2i {
    color: white;
    background-color: #3f51b5;
    padding: 6px 15px;
    min-width: 140px;
    border-radius: 4px;
    border-style: none;
    text-transform: uppercase;
}

.supportOutlet_downloadBtn__3pQmI {
    cursor: pointer;
    color: #1BC5BD;
    margin-right: 1rem;
}

.supportOutlet_actionButtonWrapper__3Gm-M {
    margin-right: 5px;
}

.supportOutlet_actionButtonWrapper__3Gm-M i {
    padding-right: 0 !important;
}
.liveIcon_liveWrapperAnimate__2x0y0 {
  position: absolute;
  top: 5px;
  right: 0;
}

.liveIcon_circleOuter__3besN {
  border: 1px solid #EC3463;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;
  opacity: .8;
  -webkit-animation: liveIcon_circle__3bTVA 2s ease-in-out infinite;
  animation: liveIcon_circle__3bTVA 2s ease-in-out infinite;
}

.liveIcon_circleInner__ZEo1K {
  background: #EC3463;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  opacity: .8;
}

.liveIcon_circleInner__ZEo1K:after {
  content: '';
  display: block;
  border: 2px solid #EC3463;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  top: -4px;
  left: -4px;
  position: absolute;
  opacity: .8;
  -webkit-animation: liveIcon_circle__3bTVA 2s ease-in-out .2s infinite;
  animation: liveIcon_circle__3bTVA 2s ease-in-out .2s infinite;
}


@-webkit-keyframes liveIcon_circle__3bTVA {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes liveIcon_circle__3bTVA {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

.liveIcon_liveWrapper__Ho9OO {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  border: 5px double;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.liveIcon_circle__3bTVA {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #EC3463;
}
.leaderboard_container__2ujXo {
    padding: 1rem;
    /* background-color: white; */
}

.leaderboard_wrapper__9O58c {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 1rem;
    position: relative;
}

.leaderboard_leftSection__1-nSJ {
    background-color: white;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.leaderboard_unnotiLogo__3YmQz {
    width: 100px;
}

.leaderboard_title__2e0l0 {
    color: #1E519F;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    /* font-family: 'Nimbus', '-apple-system', 'BlinkMacSystemFont', "Segoe UI", "Roboto"; */
}

.leaderboard_line__w4E0p {
    width: 100%;
    height: 3px;
    background-color: #0090D1;
}

.leaderboard_tableHeaderWrapper__1A-Ng {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    margin: 0.5rem 0;
}

.leaderboard_tableHeaderItem__1r7PM {
    color: #4E4E4E;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    font-size: 25px;
    font-weight: bold;
}

.leaderboard_territoryHeader__3_lbP {
    border-right: 3px solid #0090D1;
}

.leaderboard_listWrapper__1-A9L {
    width: 100%;
}

.leaderboard_listItem__3LmaI {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    color: #0D2454;
    background-color: #DDE0E1;
    margin-bottom: 1rem;
    border-radius: 1rem;
}

.leaderboard_listItem__3LmaI:first-child {
    border: 2px solid #0D2454;
    border-radius: 1rem;
    background-color: white;
}

.leaderboard_listItem__3LmaI:last-child {
    margin-bottom: 0;
}

.leaderboard_listNameWrapper__PEbDi {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 0.5rem;
    align-items: center;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    padding: 0.4rem 1rem;
    font-size: 18px;
    font-weight: 500;
    border-right: 3px solid #F4F7F9;
}

.leaderboard_dpName__1unp6 {
    text-align: center;
}

.leaderboard_angleUp__3hfrq {
    color: #5DEA53;
}

.leaderboard_angleDown__YA_CZ {
    color: #F56161;
}

.leaderboard_minus__3rRr9 {
    color: #E7CA05;
}

.leaderboard_smallText__3a4I- {
    line-height: 0.2;
    font-size: 12px;
}

.leaderboard_listProgressWrapper__257UV {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 0.5rem;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    padding: 0.3rem 6rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    text-align: center;
}

.leaderboard_lineWrapper__1V9Je {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    border-radius: 1.2rem;
    display: flex;
    align-items: center;
    padding: 3px;
}

.leaderboard_progressBar__3g1ix {
    border-radius: 1rem;
}

.leaderboard_rightSection__ZnB8E {
    background-color: white;
    padding: 1rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    /* justify-content: space-between; */
    min-height: 70vh;
}

.leaderboard_trophyTextWrapper__BWgHc {
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 1rem;
}

.leaderboard_trophyImage__2ckWB {
    height: 70px;
}

.leaderboard_bestText__2dhPN {
    color: #0D2454;
    font-size: 35px;
    font-weight: 700;
    line-height: 1;
}

.leaderboard_performanceText__WAow0 {
    color: #0D2454;
    font-size: 30px;
    font-weight: 650;
    line-height: 1;
}

.leaderboard_performerInfoWrapper__AqA-R {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-bottom: 2rem;
    text-align: center;
    color: #0D2454;
}

.leaderboard_performerImage__3a4_1 {
    width: 170px;
    height: 170px;
    object-fit: cover;
    border: 5px solid #0092D2;
    border-radius: 100%;
}

.leaderboard_performerName__lxGZV {
    font-weight: bold;
    font-size: 20px;
    text-decoration: underline;
    line-height: 1.2;
    margin-bottom: 0.5rem;
}

.leaderboard_performerDesignation__1Q0G6 {
    font-size: 18px;
    line-height: 1.2;
}

.leaderboard_mapNationalAvgWrapper__1NtWp {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.leaderboard_map__34kzR {
    width: 120px;
}

.leaderboard_nationalAverageWrapper__2GpSC {
    display: grid;
    grid-template-rows: 1fr 1fr;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    border-radius: 2rem;
}

.leaderboard_nationalAvgText__QiJ_m {
    font-size: 25px;
    font-weight: bold;
    color: #4E4E4E;
    line-height: 1;
}

.leaderboard_smallLine__Styif {
    width: 50px;
    height: 4px;
    background-color: #0092D2;
    margin-top: 8px;
}

.leaderboard_nationalAvgPercentage__LpWda {
    font-size: 40px;
    font-weight: bold;
    color: #1C519F;
    line-height: 1;
}

.leaderboard_dateWrapper__2r5vD {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    font-size: 20px;
    font-weight: bold;
    color: #0D2454;
}


@media (max-width: 1400px) {
    .leaderboard_container__2ujXo {
        padding: 1rem;
    }

    .leaderboard_title__2e0l0 {
        font-size: 30px;
    }

    .leaderboard_tableHeaderItem__1r7PM {
        font-size: 18px;
    }

    .leaderboard_listNameWrapper__PEbDi {
        font-size: 15px;
        padding: 0.3rem 1rem;
    }

    .leaderboard_smallText__3a4I- {
        line-height: 0.2;
        font-size: 10px;
    }

    .leaderboard_listProgressWrapper__257UV {
        font-size: 15px;
        padding: 0.3rem 6rem;
    }

    .leaderboard_trophyImage__2ckWB {
        height: 60px;
    }
    
    .leaderboard_bestText__2dhPN {
        font-size: 30px;
    }
    
    .leaderboard_performanceText__WAow0 {
        font-size: 25px;
    }

    .leaderboard_performerName__lxGZV {
        font-size: 16px;
    }
    
    .leaderboard_performerDesignation__1Q0G6 {
        font-size: 13px;
    }

    .leaderboard_map__34kzR {
        width: 100px;
    }
    
    .leaderboard_nationalAvgText__QiJ_m {
        font-size: 20px;
    }
    
    .leaderboard_dateWrapper__2r5vD {
        font-size: 16px;
    }
}

@media (max-width: 576px) {    
    .leaderboard_wrapper__9O58c {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
    }

    .leaderboard_title__2e0l0 {
        font-size: 25px;
    }

    .leaderboard_tableHeaderItem__1r7PM {
        font-size: 16px;
    }

    .leaderboard_listNameWrapper__PEbDi {
        font-size: 14px;
    }

    .leaderboard_listProgressWrapper__257UV {
        font-size: 14px;
        padding: 0.3rem 1rem;
    }

    .leaderboard_lineWrapper__1V9Je {
        width: 60px;
        padding: 0;
    }

    .leaderboard_trophyImage__2ckWB {
        height: 55px;
    }
    
    .leaderboard_bestText__2dhPN {
        font-size: 30px;
    }
    
    .leaderboard_performanceText__WAow0 {
        font-size: 25px;
    }

    .leaderboard_performerImage__3a4_1 {
        width: 150px;
        height: 150px;
    }
    
    .leaderboard_dateWrapper__2r5vD {
        font-size: 20px;
    }
}

@media (-webkit-device-pixel-ratio: 1.25) {
    .leaderboard_container__2ujXo {
        zoom: 0.8;
    }
}

@media (-webkit-device-pixel-ratio: 1.5) {
    .leaderboard_container__2ujXo {
        zoom: 0.9;
    }
}
.retailerInfo_generatedContent__3fAkw {
    padding-top: 1rem;
}

.retailerInfo_valueText__2xK01 {
    font-weight: bold;
}

.retailerInfo_tableWrapper__1AdFH {
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.retailerInfo_buttonWrapper__2fzgC {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
}

.retailerInfo_button__2Q-ZG {
    color: white;
    background-color: #3f51b5;
    padding: 6px 15px;
    min-width: 140px;
    border-radius: 4px;
    border-style: none;
    text-transform: uppercase;
}

.retailerInfo_downloadBtn__3U2dz {
    cursor: pointer;
    color: #1BC5BD;
}
.loanAccount_buttonWrapper__tc7dF {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.loanAccount_button__1iPFT {
    background-color: #1BC5BD;
    color: white;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px 1.5rem;
    border-radius: 0.8rem;
    border-style: none;
}

@media (max-width: 576px) {
    .loanAccount_buttonWrapper__tc7dF {
        margin-bottom: 2rem;
    }

    .loanAccount_button__1iPFT {
        font-size: 12px;
        padding: 6px 1.5rem;
    }
}
.liveIcon_liveWrapperAnimate__nPnt4 {
  position: absolute;
  top: 5px;
  right: 0;
}

.liveIcon_circleOuter__2U9Uf {
  border: 1px solid #EC3463;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;
  opacity: .8;
  -webkit-animation: liveIcon_circle__1FZs0 2s ease-in-out infinite;
  animation: liveIcon_circle__1FZs0 2s ease-in-out infinite;
}

.liveIcon_circleInner__VC4A0 {
  background: #EC3463;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  opacity: .8;
}

.liveIcon_circleInner__VC4A0:after {
  content: '';
  display: block;
  border: 2px solid #EC3463;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  top: -4px;
  left: -4px;
  position: absolute;
  opacity: .8;
  -webkit-animation: liveIcon_circle__1FZs0 2s ease-in-out .2s infinite;
  animation: liveIcon_circle__1FZs0 2s ease-in-out .2s infinite;
}


@-webkit-keyframes liveIcon_circle__1FZs0 {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes liveIcon_circle__1FZs0 {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

.liveIcon_liveWrapper__3G68H {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  border: 5px double;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.liveIcon_circle__1FZs0 {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #EC3463;
}
.nationalLaunch_container__23eqc {
    padding: 2rem;
    margin-top: -65px;
    z-index: 100000;
    background-color: #EEF0F8;
}

.nationalLaunch_topTitleSection__s1ovy {
    position: relative;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    margin-bottom: 1rem;
}

.nationalLaunch_unnotiLogo__3eGO6 {
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: 160px;
}

.nationalLaunch_title__2OdTD {
    color: #0D2454;
    font-size: 55px;
    font-weight: bold;
    text-align: center;
    font-family: 'Nimbus', '-apple-system', 'BlinkMacSystemFont', "Segoe UI", "Roboto";
}

.nationalLaunch_wrapper__3Idm1 {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 2rem;
}

.nationalLaunch_mapWrapper__1nW6R {
    grid-row: span 2;
    background-color: white;
    border-radius: 1rem;
    position: relative;
    box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.2);
    padding: 2rem;
}

.nationalLaunch_mapTitle__2H2so {
    font-size: 32px;
    font-weight: bold;
    color: #052569;
    text-align: center;
    font-family: 'Nimbus', '-apple-system', 'BlinkMacSystemFont', "Segoe UI", "Roboto";
    margin-bottom: 15px;
}

.nationalLaunch_defaultMap__2lPA5 {
    height: calc(100% - 80px);
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    z-index: 1;
}

.nationalLaunch_changedMap__3ByIm {
    height: calc(100% - 80px);
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    -webkit-animation: nationalLaunch_blink__2DayX 1s infinite;
            animation: nationalLaunch_blink__2DayX 1s infinite;
}

@-webkit-keyframes nationalLaunch_blink__2DayX {
    from {z-index: 2;}
    to {z-index: 0;}
}

@keyframes nationalLaunch_blink__2DayX {
    from {z-index: 2;}
    to {z-index: 0;}
}

.nationalLaunch_card__3dtf4 {
    background-color: white;
    border-radius: 1rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.2);
}

.nationalLaunch_progressWrapper__3D2L_ svg {
    width: 280px;
    height: 280px;
}

.nationalLaunch_progressValue__40LYr {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    -webkit-user-select: none;
            user-select: none;
    color: white;
    font-size: 70px;
    font-weight: bold;
}

.nationalLaunch_cardTitle__1ADyB {
    font-size: 40px;
    font-weight: bold;
    font-family: 'Nimbus', '-apple-system', 'BlinkMacSystemFont', "Segoe UI", "Roboto";
    text-align: center;
}

.nationalLaunch_regColor__VP9yy {
    color: #052569;
}

.nationalLaunch_secondCard__1scyd {
    background-color: #052569;
    color: white;
}

.nationalLaunch_value__BwXMm {
    font-size: 120px;
    font-weight: bold;
    color: #052569;
    text-shadow: -1px 1px 8px #0393D3,
				  1px 1px 8px #0393D3,
				  1px -1px 0 #0393D3,
				  -1px -1px 0 #0393D3;
                  height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1400px) {
    .nationalLaunch_unnotiLogo__3eGO6 {
        width: 100px;
    }

    .nationalLaunch_title__2OdTD {
        font-size: 40px;
    }

    .nationalLaunch_cardTitle__1ADyB {
        font-size: 30px;
    }

    .nationalLaunch_value__BwXMm {
        font-size: 100px;
    }

    .nationalLaunch_progressWrapper__3D2L_ svg {
        width: 180px;
        height: 180px;
    }

    .nationalLaunch_progressValue__40LYr {
        font-size: 40px;
    }
}

@media (max-width: 576px) {
    .nationalLaunch_container__23eqc {
        padding: 8px;
    }

    .nationalLaunch_unnotiLogo__3eGO6 {
        width: 50px;
    }

    .nationalLaunch_title__2OdTD {
        font-size: 25px;
    }

    .nationalLaunch_wrapper__3Idm1 {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    .nationalLaunch_mapWrapper__1nW6R {
        grid-row: span 1;
        height: 50vh;
    }

    .nationalLaunch_cardTitle__1ADyB {
        font-size: 22px;
    }

    .nationalLaunch_progressWrapper__3D2L_ svg {
        width: 250px;
        height: 250px;
    }

    .nationalLaunch_progressValue__40LYr {
        font-size: 60px;
    }
}
.smsGateway_gatewayItem__1fAZR {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  max-width: calc(200px + 5vw);
  margin-bottom: 1rem;
}

.smsGateway_label__39JbA {
  font-weight: bold;
  flex: 1 1;
  text-transform: capitalize;
}

.smsGateway_input__2iW5c {
  cursor: pointer;
  flex: 1 1;
}
.ekycReport_filterSection__1BVYD {
  display: flex;
  justify-content: flex-end;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  margin: 1rem 0;
}

.updateOutletPhone_fiInputWrapper__2O1vY {
    /* display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 1rem;
    align-items: flex-end; */
}
