.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.select__menu {
  text-align: left;
  text-align-last: left;
  /* webkit*/
}

/* for styling selection dropdown */
.dashboard-selection .dropdown-container {
  padding: 5px 4px;
  color: black;
  background-color: #ECEEEF !important;
  border-top-right-radius: 0.8rem !important;
  border-bottom-right-radius: 0.8rem !important;
  border: none !important;
  outline: none !important;
  border-style: none !important;
  max-width: 100px !important;
}

.dashboard-selection .dropdown-heading {
  height: fit-content !important;
}

.dashboard-selection .dropdown-content {
  min-width: 165px;
  right: 0;
}

/* daterange custom design */
.react-daterange-picker__wrapper {
  padding: 3.5px;
  border-radius: 0.8rem;
  border: none !important;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.leaderboard-dateRange .react-daterange-picker__wrapper {
  border: none !important;
  background: transparent;
  box-shadow: none;
  color: white;
}

.leaderboard-dateRange .react-daterange-picker__button svg {
  stroke: white;
}

.leaderboard-dateRange .react-daterange-picker__wrapper input {
  color: white;
}

.custom-multiselect .dropdown-container{
  max-width: 350px !important;
  width: 350px !important;
}

@media (max-width: 1500px) {
  .dashboard-selection .dropdown-container {
    max-width: 95px !important;
  }

  .custom-multiselect .dropdown-container{
    max-width: 267px !important;
    width: 267px !important;
  }
}

@media screen and (min-width:577px) and (max-width:1280px) and (orientation:landscape) {
  .dashboard-selection .dropdown-container {
    max-width: 45px !important;
  }
}

@media (max-width: 576px) {
  .dashboard-selection .dropdown-container {
    max-width: 100% !important;
  }
}

/* react customizable progressbar */
.RCP {
  width: fit-content !important;
  height: fit-content !important;
}


/* custom scroll-bar */
.custom-scrollbar::-webkit-scrollbar {
  width: 7px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #1E519F;
  border: 5px solid transparent;
  background-clip: content-box;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #1E519F;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #0092D2;
}

@font-face {
  font-family: "Nimbus";
  src: local("Nimbus"),
    url("./fonts/Nimbus/Nimbus.otf") format("opentype");
}